<template>
    <div class="footer-block">
        <InfoSection class="footer-section" />
        <ContentSection v-for="content in content_list" :key="content.title" :title="content.title" :list="content.list" />
        <QuoteSection class="footer-section" />
    </div>
</template>

<script>
    import InfoSection from './InfoSection.vue'
    import ContentSection from './ContentSection.vue'
    import QuoteSection from './QuoteSection.vue'

    export default {
        name: 'NavFooter',
        components: {
            InfoSection,
            ContentSection,
            QuoteSection
        },
        data() {
            return {
                content_list: ''
            }
        },
        created() {
            this.content_list = [
                {
                    title: '文章類別',
                    list: [
                        '平面媒體',
                        '法律叢書',
                        '網路媒體',
                        '更多類別'
                    ]
                },
                {
                    title: '文章詞條',
                    list: [
                        '英美法系',
                        '歐陸法系',
                        '順法抗爭',
                        '更多詞條'
                    ]
                }
            ]
        }
    }
</script>

<style scoped>
    .footer-block{
        width: 100%;
        height: 320px;
        background-color: #9de0e0;
        margin-top: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-section{
        width: 22%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
</style>