import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDhFWUfs_NsaOFNl6s2cHhyu4MLw5BTcKU",
    authDomain: "isper-31bd8.firebaseapp.com",
    projectId: "isper-31bd8",
    storageBucket: "isper-31bd8.appspot.com",
    messagingSenderId: "16265034203",
    appId: "1:16265034203:web:48affa691c3e3e0f50cffe",
    measurementId: "G-ZBQXQVEKK8"
};

export const app = initializeApp(firebaseConfig);