<template>
    <div>
        <HeaderBlock></HeaderBlock>
        <LatestPosts />
        <PopularPosts />
        <PostTags />
    </div>
</template>

<script>
    import HeaderBlock from '../components/HeaderBlock'
    import LatestPosts from '../components/LatestPosts'
    import PopularPosts from '../components/PopularPosts'
    import PostTags from '../components/PostTags'

    export default {
        name: 'HomePage',
        components: {
            HeaderBlock,
            LatestPosts,
            PopularPosts,
            PostTags,
        },
    }
</script>