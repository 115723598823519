<template>
    <div v-if="search_tags.length">
        <SearchPageTitle />
        <div class="search-result-block" v-if="search_title">
            <div class="search-result">
                <div class="result-img"></div>
                <div class="result-info">
                    <h1>臨時用又想不到的標題</h1>
                    <small>2021-05-12</small>
                    <p>文章的內容，反正會有很多就對了...</p>
                </div>
                <div v-if="search_type === 'category'" class="result-tag">
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[0].color}"> {{ search_tags[0].name }} </button>
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[4].color}"> {{ search_tags[4].name }} </button>
                </div>
                <div v-else-if="search_type === 'tag'" class="result-tag">
                    <button class="tag-btn post-tags" style="text-decoration: none"> {{ "# " + search_tags[0].tag_name }} </button>
                </div>
            </div>
            <div class="search-result">
                <div class="result-img"></div>
                <div class="result-info">
                    <h1>臨時用又想不到的標題</h1>
                    <small>2021-05-12</small>
                    <p>文章的內容，反正會有很多就對了...</p>
                </div>
                <div v-if="search_type === 'category'" class="result-tag">
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[2].color}"> {{ search_tags[2].name }} </button>
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[3].color}"> {{ search_tags[3].name }} </button>
                </div>
                <div v-else-if="search_type === 'tag'" class="result-tag">
                    <button class="tag-btn post-tags" style="text-decoration: none"> {{ "# " + search_tags[5].tag_name }} </button>
                </div>
            </div>
            <div class="search-result">
                <div class="result-img"></div>
                <div class="result-info">
                    <h1>臨時用又想不到的標題</h1>
                    <small>2021-05-12</small>
                    <p>文章的內容，反正會有很多就對了...</p>
                </div>
                <div v-if="search_type === 'category'" class="result-tag">
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[5].color}"> {{ search_tags[5].name }} </button>
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[6].color}"> {{ search_tags[6].name }} </button>
                </div>
                <div v-else-if="search_type === 'tag'" class="result-tag">
                    <button class="tag-btn post-tags" style="text-decoration: none"> {{ "# " + search_tags[2].tag_name }} </button>
                </div>
            </div>
            <div class="search-result">
                <div class="result-img"></div>
                <div class="result-info">
                    <h1>臨時用又想不到的標題</h1>
                    <small>2021-05-12</small>
                    <p>文章的內容，反正會有很多就對了...</p>
                </div>
                <div v-if="search_type === 'category'" class="result-tag">
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[1].color}"> {{ search_tags[1].name }} </button>
                    <button class="tag-btn post-cat" :style="{backgroundColor: search_tags[6].color}"> {{ search_tags[6].name }} </button>
                </div>
                <div v-else-if="search_type === 'tag'" class="result-tag">
                    <button class="tag-btn post-tags" style="text-decoration: none"> {{ "# " + search_tags[4].tag_name }} </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchPageTitle from './SearchPageTitle'
    import { db } from '../db'
    import { collection, getDocs, query } from "firebase/firestore"; 

    async function getPostTags(thenFunction) {
        const post_tag_query = query(
            collection(db, "post_tags_coll"),
        );

        const query_snapshot = await getDocs(post_tag_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    async function getCatTags(thenFunction) {
        const search_tags_query = query(
            collection(db, "post_cat_coll"),
        );

        const query_snapshot = await getDocs(search_tags_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    export default {
        name: 'SearchPageCat',
        components: {
            SearchPageTitle
        },
        data() {
            return {
                search_type: "",
                search_tags: []
            }
        },
        created() {
            this.search_type = this.$route.params.type;
            
            if(this.search_type === "category"){
                getCatTags(search_tags => {
                    this.search_tags.push(search_tags)
                    if(search_tags.id === this.$route.params.searchType){
                        this.search_title = search_tags.name
                    }
                })
            }
            else if(this.search_type === "tag"){
                getPostTags(search_tags => {
                    this.search_tags.push(search_tags)
                    if(search_tags.tag_id === this.$route.params.searchType){
                        this.search_title = search_tags.tag_name
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .search-tags-block{
        width: 60%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-tags{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .search-result-block{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search-result{
        width: 60%;
        height: 160px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #fff;
        border-radius: 8px;
        margin: 20px 0;
        padding: 10px;
        transition: .25s;
    }
    .search-result:hover{
        cursor: pointer;
        background-color: #f3f3f3;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .result-img{
        width: 15%;
        height: 70%;
        margin-inline: 30px;
        border-radius: 8px;
        background-image: url("https://picsum.photos/200/200");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .result-info{
        width: 63%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .result-info h1{
        font-size: 26px;
    }
    .result-info small{
        color: #8c8c8c;
    }
    .result-info p{
        font-size: 16px;
        color: #757575;
    }
    .result-tag{
        width: 22%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-inline: 30px;
    }    
    .tag-btn{
        width: fit-content;
        outline: none;
        border: none;
        font-size: 16px;
        border-radius: 8px;
        transition: .25s;
        padding: 5px 15px;
    }
    .post-cat{       
        height: 40px; 
        color: #fff;
    }
    .post-cat:hover{
        cursor: pointer;
    }
    .post-tags{
        height: fit-content;
        border-radius: 6px;
        background-color: #9de0e0;
        color: #156d6d;
        text-align: center;
        margin: 5px;
    }
    .post-tags:hover{
        cursor: pointer;
    }
</style>