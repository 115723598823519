<template>
    <div class="popular-title">
        <h3>熱門文章</h3>
    </div>
    <div class="popular-posts-row">
        <PopularSinglePost :popular_posts="popular_posts" />
    </div>
</template>

<script>
    import PopularSinglePost from './PopularSinglePost'

    export default {
        name: 'PopularPosts',
        components: {
            PopularSinglePost,
        },
        data() {
            return {
                popular_posts: [],
            }
        },
        created() {
            this.popular_posts = [
                {
                    post_id: 1,
                    post_title: "第一篇的標題但是我還不知道要打什麼",
                    post_content: "第一篇的內容但是同樣的...",
                    post_date: "2021 / 05 / 11",
                },
                {
                    post_id: 2,
                    post_title: "第二篇的標題但是我還不知道要打什麼",
                  post_content: "第二篇的內容但是同樣的...",
                    post_date: "2021 / 05 / 11",
                },
                {
                    post_id: 3,
                    post_title: "第三篇的標題但是我還不知道要打什麼",
                    post_content: "第三篇的內容但是同樣的...",
                    post_date: "2021 / 05 / 11",
                },
                {
                    post_id: 4,
                    post_title: "第四篇的標題但是我還不知道要打什麼",
                    post_content: "第四篇的內容但是同樣的...",
                    post_date: "2021 / 05 / 11",
                },
            ]
        }
    }
</script>

<style scoped>
    .popular-title{
        width: 80%;
        margin: auto;
        margin-top: 4rem;
        border-bottom: 2px solid #d7d7d7;
    }
    .popular-title h3{
        width: 18%;
        padding: 5px 10px;
        border-bottom: 4px solid #000;
        font-size: 28px;
        transform: translateY(2px);
    }
    .popular-posts-row{
        width: 80%;
        height: fit-content;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
</style>