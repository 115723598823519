<template>
    <div>
        <PostImg :title="post_title" />
        <div class="post-content-block">
            <LikeTitle :like_title="like_title" />
            <div class="post-content">
                <CatTags :post_cat="post_cat" :post_date="post_date" />
                <ContentWords :content_words="content_words" />
                <ContentTags :content_tags="content_tags" />
            </div>
        </div>
    </div>
</template>

<script>
    import PostImg from '../components/PostImg'
    import LikeTitle from '../components/LikeTitle'
    import CatTags from '../components/CatTags'
    import ContentWords from '../components/ContentWords'
    import ContentTags from '../components/ContentTags'
    import { db } from '../db'
    import { collection, getDocs, query, where } from "firebase/firestore"; 

    export default {
        name: "PostContent",
        components: {
            PostImg,
            LikeTitle,
            CatTags,
            ContentWords,
            ContentTags,
        },
        data() {
            return {
                post_title: "",
                post_date: "",
                like_title: [],
                post_cat: [],
                content_words: [],
                content_tags: [],
            }
        },
        created() {
            const postId = this.$route.params.postId
            var pt = "", pd = "", pc = [], cw = [], ct = []

            async function postData(){
                const post_ref = collection(db, "post_words_coll")
                const post_query = query(post_ref, where("post_id", "==", postId))
                const post_words = await getDocs(post_query)
                // const post_words = await getDoc(doc(db, "post_words_coll", "post_words_1"))

                post_words.forEach((doc) => {
                    if(doc.exists()){
                        pt = doc.data()['post_title']
                        pd = doc.data()['post_date']
                        pc = doc.data()['post_cat']
                        cw = doc.data()['post_content']
                        ct = doc.data()['post_tag']
                    }
                })
                
            }

            postData().then(() => {
                this.post_title = pt,
                this.post_date = pd,
                this.post_cat = pc,
                this.content_words = cw,
                this.content_tags = ct
            })

            this.like_title = [
                "英國與法國的...",
                "近年來的社會...",
                "光與火的交織...",
                "我掰不下去了..."
            ]
        },
    }
</script>

<style scoped>
    .post-content-block{
        width: 100%;
        height: max-content;
        margin-top: 3rem;
        padding: 30px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
    .post-content{
        width: 70%;
        height: fit-content;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
    }
</style>