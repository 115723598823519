<template>
    <router-link to="/content" style="text-decoration: none; color: inherit;" class="popular-post" v-for="post in popular_posts" :key="post.id">
        <div class="popular-post-img"></div>
        <h3><span> {{ post.post_title }} </span></h3>
        <p class="popular-post-content"> {{ post.post_content }} </p>
        <p class="popular-post-date"> {{ post.post_date }} </p>
    </router-link>
</template>

<script>
    export default {
        name: 'PopularSinglePost',
        props:{
            popular_posts: Array,
        }
    }
</script>

<style scoped>
    .popular-post{
        width: 34%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 10px 0;
        border-radius: 6px;
    }
    .popular-post:hover{
        cursor: pointer;
    }
    .popular-post-img{
        width: 100%;
        height: 200px;
        background-image: url("https://picsum.photos/400/300");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: .2s;
    }
    .popular-post:hover .popular-post-img{
        filter: brightness(.8);
    }
    .popular-post h3{
        font-size: 24px;
        font-weight: 600;
        margin: 10px 0;
    }
    .popular-post span{
        transition: .2s;
    }
    .popular-post:hover span{
        -webkit-box-shadow: inset 0px -18px 0px -6px #51afaf83;
        box-shadow: inset 0px -18px 0px -6px #51afaf83;
    }
    .popular-post-content{
        font-size: 18px;
        color: #8c8c8c;
        margin: 10px 0;
    }
    .popular-post-date{
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
        margin: 10px 0;
    }
</style>