import {createRouter, createWebHistory} from 'vue-router'

import HomePage from '../views/HomePage'
import PostContent from '../views/PostContent'
import SearchPageTitle from '../views/SearchPageTitle'
import SearchPageCat from '../views/SearchPageCat'
import SignIn from '../views/SignIn'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/content/:postId',
        name: 'PostContent',
        component: PostContent
    },
    {
        path: '/search/:type',
        name: 'SearchPageTitle',
        component: SearchPageTitle
    },
    {
        path: '/search/:type/:searchType',
        name: 'SearchPageCat',
        component: SearchPageCat
    },
    {
        path: '/signin',
        name: 'SignIn',
        component: SignIn
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || new Promise((resolve) => {
            setTimeout(() => {
                resolve({ top: 0 })
            }, 300)
        })
    },
})

export default router