    <template>
    <div class="tags-block">
        <PostSingleTag title="內容詞條" :post_tags="post_tags" />
    </div>
</template>

<script>
    import PostSingleTag from './PostSingleTag'
    import { db } from '../db'
    import { collection, getDocs, query, limit } from "firebase/firestore"; 

    async function getPostTags(thenFunction) {
        const post_tag_query = query(
            collection(db, "post_tags_coll"),
            limit(8)
        );

        const query_snapshot = await getDocs(post_tag_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data().tag_name)
            }
        });
    }

    export default {
        name: 'PostTags',
        components: {
            PostSingleTag
        },
        data() {
            return {
                post_tags: []
            }
        },
        created() {
            getPostTags(post_tags => {
                this.post_tags.push(post_tags)
            })
        }
    }
</script>

<style scoped>
    .tags-block{
        width: 80%;
        height: 400px;
        margin: auto;
        margin-top: 4rem;
        border-radius: 30px;
        background-color: #fff;
        padding: 10px 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .tags-block h1{
        font-size: 32px;
        letter-spacing: 4px;
    }
    .tags-row{
        width: 75%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .post-tags{
        width: fit-content;
        min-width: 16%;
        height: fit-content;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #9de0e0;
        font-size: 20px;
        color: #156d6d;
        text-align: center;
    }
</style>