<template>
    <div class="cat-tags">
        <button v-for="tag in post_cat" :key="tag.name" class="tag-btn" :style="{backgroundColor: tag.color}"> {{ tag.name }} </button>
    </div>
    <small>創作時間: {{ post_date }} | <router-link to="/" style="text-decoration: none; color: inherit;">返回首頁</router-link></small>
</template>

<script>
    export default {
        name: "CatTags",
        props: {
            post_cat: Array,
            post_date: String
        }
    }
</script>

<style scoped>
    .cat-tags{
        width: 100%;
        height: fit-content;
    }
    small{
        margin-top: 20px;
        letter-spacing: 2px;
        color: #757575;
        font-size: 15px;
    }
    .tag-btn{
        width: fit-content;
        height: 40px;
        padding: 5px 15px;
        outline: none;
        border: none;
        border-radius: 8px;
        margin-right: 15px;
        font-size: 16px;
        color: #fff;
        transition: .25s;
    }
    .tag-btn:hover{
        cursor: pointer;
        filter: brightness(.8);
    }
</style>