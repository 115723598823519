<template>
    <div class="like-posts">
        <h3>你可能會喜歡</h3>
        <li v-for="title in like_title" :key="title">
            <span> {{ title }} </span>
        </li>
    </div>
</template>

<script>
    export default {
        name: "LikeTitle",
        props: {
            like_title: Array
        },
    }
</script>

<style scoped>
    .like-posts{
        width: 25%;
        height: fit-content;
        min-height: 400px;
        background: #fff;
        border-radius: 8px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .like-posts h3{
        font-size: 26px;
        letter-spacing: 3px;
        color: #156d6d;
    }
    .like-posts li{
        width: 100%;
        text-align: center;
        padding: 10px;
        font-size: 20px;
        list-style: circle;
        letter-spacing: 2px;
    }
    .like-posts span{
        transition: .2s;
    }
    .like-posts li:hover span{
        cursor: pointer;
        -webkit-box-shadow: inset 0px -18px 0px -6px #51afaf83;
        box-shadow: inset 0px -18px 0px -6px #51afaf83;
    }
</style>