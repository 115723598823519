<template>
    <div class="header-block">
        <p><span>歪踢法律工作室</span><span>YT Law Studio</span></p>
        <img src="../assets/headerblock.svg">
    </div>
</template>

<script>
    export default {
        name: 'HeaderBlock',
    }
</script>

<style scoped>
    .header-block{
        width: calc(100% - 80px);
        height: 350px;
        background-color: #9de0e0cc;
        margin: auto;
        margin-top: 10px;
        padding: 0 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-block p{
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 60px 40px;
        transform: translateX(-80px);
        width: 100%;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .header-block p span:nth-child(1){
        font-size: 50px;
        font-weight: 600;
    }
    .header-block p span:nth-child(2){
        font-family: 'Nunito', sans-serif;
        font-size: 35px;
    }
    .header-block img{
        height: 75%;
        width: 100%;
    }
</style>