<template>
    <h1> {{ title }} </h1>
    <div v-for="i in 2" :key="i" class="tags-row">
        <p v-for="j in 4" :key="j" class="post-tags"> {{ "# " + post_tags[(j - 1) + (i - 1) * 4] }} </p>
    </div>
</template>

<script>
    export default {
        name: 'PostSingleTag',
        props: {
            title: String,
            post_tags: Array
        }
    }
</script>

<style scoped>
    .tags-row{
        width: 75%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .post-tags{
        width: fit-content;
        min-width: 16%;
        height: fit-content;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #9de0e0;
        font-size: 20px;
        color: #156d6d;
        text-align: center;
    }
</style>