<template>
    <div class="footer-section content-section">
        <h3> {{ title }} </h3>
        <p v-for="l in list" :key="l">
            {{ l }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ContentSection',
        props: {
            title: String,
            list: Array
        }
    }
</script>

<style scoped>
    .content-section{
        align-items: center;
    }
    .content-section h3{
        font-size: 24px;
        letter-spacing: 2px;
        color: #156d6d;
    }
    .content-section p{
        font-size: 18px;
        transition: .2s;
    }
    .content-section p:last-child{
        color: #156d6d;
    }
    .content-section p:hover{
        cursor: pointer;
        -webkit-box-shadow: inset 0px -18px 0px -6px #51afaf83;
        box-shadow: inset 0px -18px 0px -6px #51afaf83;
    }
</style>