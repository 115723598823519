<template>
    <div class="header-bar">
        <img src="../assets/logo.png">
        <SearchTags search_text="文章搜尋" />
    </div>
</template>

<script>
    import SearchTags from './SearchTags'

    export default {
        name: 'NavHeader',
        components: {
            SearchTags
        }
    }
</script>

<style scoped>
    .header-bar{
        width: calc(100% - 80px);
        height: 100px;
        margin: auto;
    }
    .header-bar img{
        height: 100%;
        float: left;
        transform: scale(0.6);
    }
</style>