<template>
    <div class="info-section">
        <h1>歪踢法律工作室 <br /> YT Law Studio</h1>
        <p><b>Email</b>: christine@isper.net <br /> <b>IG:</b> 可能會有吧</p>
    </div>
</template>

<script>
    export default {
        name: 'InfoSection'
    }
</script>

<style scoped>
    .info-section h1{
        font-size: 30px;
        letter-spacing: 3px;
        color: #156d6d;
    }
    .info-section p{
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 2.4;
    }
</style>