<template>
	<NavHeader />
	<router-view v-slot="{Component}">
		<transition name="fade" mode="out-in">
			<component :is="Component" :key="$route.path" />
		</transition>
	</router-view>
	<NavFooter />
</template>

<script>
import NavHeader from './components/NavHeader'
import NavFooter from './components/NavFooter'

import { db } from './db'
import { doc, getDoc } from "firebase/firestore"; 

const users = doc(db, "users", "fN4vAs5pK14ak58etKCt");


async function getUsers(thenFunction) {
	try {
		const user = await getDoc(users)
		if(user.exists()){
			thenFunction(user.data())
		}
	} catch (error) {
		console.log(error)
	}
}

export default {
	name: 'App',
	components: {
		NavHeader,
		NavFooter
	},
	data() {
		return {
			users: []
		} 
	},
	created() {
		getUsers(users => {
			this.users = users
		})
	},
	watch:{
		$route (){
			document.querySelectorAll('.tag-btn').forEach(function(el) {
                if(el.classList.contains('show-tags')){
                    el.classList.remove('show-tags');
                    el.classList.add('hide-tags');
                }
            });
		}
	}
}
</script>

<style>
	::-moz-selection {
		background: #88dddd77;
	}
	::selection {
		background: #88dddd77;
	}
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Nunito', 'Noto Serif TC';
	}
	body{
		background-color: #f5f5f5;
		overflow-x: hidden;
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>