<template>
    <div class="latest-title">
        <h3>最新文章</h3>
    </div>
    <div class="latest-posts">
        <LatestSinglePost :latest_posts="latest_posts" />
    </div>
</template>

<script>
    import LatestSinglePost from './LatestSinglePost';

    export default {
        name: 'LatestPosts',
        components: {
            LatestSinglePost,
        },
        data() {
            return {
                latest_posts: [],
            }
        },
        created() {
            this.latest_posts = [
                {
                    post_id: 1,
                    post_type: "large",
                    post_title: "第一篇的標題但是我還不知道要打什麼",
                    post_content: "第一篇的內容但是同樣的...",
                    post_date: "2021 / 05 / 11",
                },
                {
                    post_id: 2,
                    post_type: "small",
                    post_content: [
                        {
                            id: 2,
                            title: "第二篇的標題但不知道要打什麼",
                            date: "2021 / 05 / 11",
                        },
                        {
                            id: 3,
                            title: "第三篇的標題但不知道要打什麼",
                            date: "2021 / 05 / 11",
                        }
                    ]
                },
                {
                    post_id: 3,
                    post_type: "small",
                    post_content: [
                        {
                            id: 4,
                            title: "第四篇的標題但不知道要打什麼",
                            date: "2021 / 05 / 11",
                        },
                        {
                            id: 5,
                            title: "第五篇的標題但不知道要打什麼",
                            date: "2021 / 05 / 11",
                        }
                    ]
                },
            ]
        }
    }
</script>

<style scoped>
    .latest-title{
        width: 80%;
        margin: auto;
        margin-top: 4rem;
        border-bottom: 2px solid #d7d7d7;
    }
    .latest-title h3{
        width: 18%;
        padding: 5px 10px;
        border-bottom: 4px solid #000;
        font-size: 28px;
        transform: translateY(2px);
    }
    .latest-posts{
        width: 80%;
        height: 400px;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>